import Home from 'components/static/Home';
import Contact from 'components/static/Contact';
import About from 'components/static/About';
import TermsAndConditions from 'components/static/TermsAndConditions';
import Login from 'components/Auth/Login';
import Signup from 'components/Auth/Signup';
import ForgotPassword from 'components/Auth/ForgotPassword';
import LoginLayout from 'components/common/Layout/LoginLayout';
import AppLayout from 'components/common/Layout/AppLayout';

import React, { lazy, Suspense } from 'react';
// import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LocalStorageService from 'components/_Services/LocalStorageService';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setIsAuthenticatedData } from 'slices/login';
import Cancel from 'components/Auth/cancel';
import Pricing from 'components/static/Pricing';

import ProtectedRoute from './routes/ProtectRoute';
import PrivateRoute from './routes/PrivateRoute';
import PaymentRoute from './routes/PaymentRoute';
import NotFound from 'components/static/NotFound';
import UpdateStream from 'components/App/Profile/UpdateStream';

const HomeApp = lazy(() => import('components/App/Home/HomeApp'));
const BlogDetails = lazy(() => import('components/App/Home/BlogDetails'));
const GiveawayDetails = lazy(() => import('components/App/Home/GiveawayDetails'));
const GiveawayResults = lazy(() => import('components/App/Home/GiveawayResults'));
const MembersPack = lazy(() => import('components/App/Home/MembersPack'));
const ShippingConfirmation = lazy(() => import('components/App/Home/ShippingConfirmation'));
const Calender = lazy(() => import('components/App/Events/Calendar'));
const Events = lazy(() => import('components/App/Events/Events'));
const EventDetails = lazy(() => import('components/App/Events/EventDetails'));
const Profile = lazy(() => import('components/App/Profile/Profile'));
const PersonalDetails = lazy(() => import('components/App/Profile/PersonalDetails'));
const ChangePassword = lazy(() => import('components/App/Profile/ChangePassword'));
const UpdatePhoneNumber = lazy(() => import('components/App/Profile/UpdatePhoneNumber'));
const FavouritesDetails = lazy(() => import('components/App/Profile/FavouritesDetails'));
const PaymentDetails = lazy(() => import('components/App/Profile/PaymentDetails'));
const MembershipDetails = lazy(() => import('components/App/Profile/MembershipDetails'));
const UpgradeMembership = lazy(() => import('components/App/Profile/UpgradeMembership'));
const NotificationsSettings = lazy(() => import('components/App/Profile/NotificationsSettings'));
const HelpAndSupport = lazy(() => import('components/App/Profile/HelpAndSupport'));
const MembershipPlans = lazy(() => import('components/Auth/MembershipPlans'));
const MembershipFailed = lazy(() => import('components/Auth/MembershipFailed'));
const MembershipSuccess = lazy(() => import('components/Auth/MembershipSuccess'));
const Notifications = lazy(() => import('components/App/Notifications/Notifications'));
const Racing = lazy(() => import('components/App/Racing/Racing'));
const ArionStable = lazy(() => import('components/App/Racing/ArionStable'));
const RacingData = lazy(() => import('components/App/Racing/RacingData'));
const StableDetails = lazy(() => import('components/App/Racing/StableDetails'));
const StableUpdates = lazy(() => import('components/App/Racing/StableUpdates'));
const FullRaceCalendar = lazy(() => import('components/App/Racing/FullRaceCalendar'));
const RaceResults = lazy(() => import('components/App/Racing/RaceResults'));
const LiveRace = lazy(() => import('components/App/Racing/LiveRace'));
const Partners = lazy(() => import('components/App/Partners/Partners'));
const PartnerDetails = lazy(() => import('components/App/Partners/PartnerDetails'));
const Concierge = lazy(() => import('components/App/Concierge/Concierge'));
const PurchaseHorse = lazy(() => import('components/App/Concierge/PurchaseHorse'));
const FormSyndicate = lazy(() => import('components/App/Concierge/FormSyndicate'));
const RaceHorseManagement = lazy(() => import('components/App/Concierge/RaceHorseManagement'));
const Platinum = lazy(() => import('components/App/Platinum/Platinum'));
const PlatinumExperience = lazy(() => import('components/App/Platinum/PlatinumExperience'));
const PlatinumApplication = lazy(() => import('components/App/Platinum/PlatinumApplication'));
const RaceHorsePurchase = lazy(() => import('components/App/Platinum/RaceHorsePurchase'));
const ExclusiveGlobalEvents = lazy(() => import('components/App/Platinum/ExclusiveGlobalEvents'));
const RacingSyndicate = lazy(() => import('components/App/Platinum/RacingSyndicate'));
const ScheduleTime = lazy(() => import('components/App/Platinum/ScheduleTime'));

const Test = () => <h1>404</h1>;

function Loader() {
  return (
    <div className="loader_wrapper">
      <div className="loader_container">
        <div className="full_width">
          <img
            className="site_logo"
            src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ra-loader-img.gif"
            width={50}
            alt=""
          />
        </div>
        <h6>Loading...</h6>
      </div>
    </div>
  );
}

function App() {
  const dispatch = useAppDispatch();
  const getUserInfo = async () => {
    const userinfo = await LocalStorageService.getItem('userInfo');
    if (userinfo !== '') {
      const token = JSON.parse(userinfo);

      if (token.token) {
        dispatch(setIsAuthenticatedData(true));
      }
    }
  };
  React.useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<LoginLayout />}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="verificationOtp" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="about" element={<About />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="contact" element={<Contact />} />
        <Route path="contact/:type" element={<Contact />} />
        <Route path="termsandconditions" element={<TermsAndConditions />} />
      </Route>

      <Route path="*" element={<NotFound />} />
      <Route path="app/*" element={<NotFound />} />

      {/* <Suspense fallback="Loading...."> */}
      <Route
        path="/app/*"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route
          path="home"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <HomeApp activePage="home" />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="editorial"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <HomeApp activePage="editorial" />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="home/blogdetails"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <BlogDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="home/blogdetails/:id"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <BlogDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="partners"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Partners activePage="partners" />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="industrypartners"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Partners activePage="industrypartners" />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="partners/partnerdetails"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <PartnerDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="partners/partnerdetails/:id"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <PartnerDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="racing"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Racing />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="racing/arionstable"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <ArionStable />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="racing/racingdata"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <RacingData />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="racing/stabledetails"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <StableDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="racing/stabledetails/:id"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <StableDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="racing/stableupdates"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <StableUpdates />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="racing/fullracecalendar"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <FullRaceCalendar />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="racing/raceresults/:venueId/:raceNumber"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <RaceResults />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="racing/liverace"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <LiveRace />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="membership"
          element={
            <PaymentRoute>
              <MembershipPlans />
            </PaymentRoute>
          }
        />

        <Route
          path="membershipplans"
          element={
            <React.Suspense fallback={<Loader />}>
              <MembershipPlans />
            </React.Suspense>
          }
        />

        <Route
          path="membershipsuccess"
          element={
            <React.Suspense fallback={<Loader />}>
              <MembershipSuccess />
            </React.Suspense>
          }
        />
        <Route
          path="cancel"
          element={
            <React.Suspense fallback={<Loader />}>
              <Cancel />
            </React.Suspense>
          }
        />

        <Route
          path="membershipfailed"
          element={
            <React.Suspense fallback={<Loader />}>
              <MembershipFailed />
            </React.Suspense>
          }
        />

        <Route
          path="concierge"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Concierge />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="concierge/purchasehorse"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <PurchaseHorse />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="concierge/formsyndicate"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <FormSyndicate />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="concierge/racehorsemanagement"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <RaceHorseManagement />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="platinum"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Platinum />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="platinum/platinumexperience"
          element={
            <React.Suspense fallback={<Loader />}>
              <PlatinumExperience />
            </React.Suspense>
          }
        />

        <Route
          path="platinum/scheduletime"
          element={
            <React.Suspense fallback={<Loader />}>
              <ScheduleTime />
            </React.Suspense>
          }
        />

        <Route
          path="platinum/racehorsepurchasing"
          element={
            <React.Suspense fallback={<Loader />}>
              <RaceHorsePurchase />
            </React.Suspense>
          }
        />

        <Route
          path="platinum/racehorsemanagement"
          element={
            <React.Suspense fallback={<Loader />}>
              <RaceHorseManagement />
            </React.Suspense>
          }
        />

        <Route
          path="platinum/exclusiveglobalevents"
          element={
            <React.Suspense fallback={<Loader />}>
              <ExclusiveGlobalEvents />
            </React.Suspense>
          }
        />

        <Route
          path="platinum/racingsyndicate"
          element={
            <React.Suspense fallback={<Loader />}>
              <RacingSyndicate />
            </React.Suspense>
          }
        />

        <Route
          path="platinum/platinumapplication"
          element={
            <React.Suspense fallback={<Loader />}>
              <PlatinumApplication />
            </React.Suspense>
          }
        />

        <Route
          path="events"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Events />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="events/:month"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Events />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="events/eventdetails"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <EventDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />
        <Route
          path="events/eventdetails/:month/:id"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <EventDetails />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="events/calendar"
          element={
            <React.Suspense fallback={<Loader />}>
              <Calender />
            </React.Suspense>
          }
        />

        <Route
          path="calendar"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Calender />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="calendar/eventdetails"
          element={
            <React.Suspense fallback={<Loader />}>
              <EventDetails />
            </React.Suspense>
          }
        />
        <Route
          path="calendar/eventdetails/:id"
          element={
            <React.Suspense fallback={<Loader />}>
              <EventDetails />
            </React.Suspense>
          }
        />

        <Route
          path="home/giveawaydetails"
          element={
            <React.Suspense fallback={<Loader />}>
              <GiveawayDetails />
            </React.Suspense>
          }
        />

        <Route
          path="home/giveawayresults"
          element={
            <React.Suspense fallback={<Loader />}>
              <GiveawayResults />
            </React.Suspense>
          }
        />

        <Route
          path="home/memberspack"
          element={
            <React.Suspense fallback={<Loader />}>
              <MembersPack />
            </React.Suspense>
          }
        />

        <Route
          path="home/shippingconfirmation"
          element={
            <React.Suspense fallback={<Loader />}>
              <ShippingConfirmation />
            </React.Suspense>
          }
        />

        <Route
          path="profile"
          element={
            <PaymentRoute>
              <React.Suspense fallback={<Loader />}>
                <Profile />
              </React.Suspense>
            </PaymentRoute>
          }
        />

        <Route
          path="profile/personaldetails"
          element={
            <React.Suspense fallback={<Loader />}>
              <PersonalDetails />
            </React.Suspense>
          }
        />

        <Route
          path="profile/changepassword"
          element={
            <React.Suspense fallback={<Loader />}>
              <ChangePassword />
            </React.Suspense>
          }
        />

        <Route
          path="profile/updatephonenumber"
          element={
            <React.Suspense fallback={<Loader />}>
              <UpdatePhoneNumber />
            </React.Suspense>
          }
        />

        <Route
          path="profile/updatestream"
          element={
            <React.Suspense fallback={<Loader />}>
              <UpdateStream />
            </React.Suspense>
          }
        />

        <Route
          path="profile/paymentdetails"
          element={
            <React.Suspense fallback={<Loader />}>
              <PaymentDetails />
            </React.Suspense>
          }
        />

        <Route
          path="profile/favourites"
          element={
            <React.Suspense fallback={<Loader />}>
              <FavouritesDetails />
            </React.Suspense>
          }
        />

        <Route
          path="profile/membershipdetails"
          element={
            <React.Suspense fallback={<Loader />}>
              <MembershipDetails />
            </React.Suspense>
          }
        />

        <Route
          path="profile/membershipdetails/upgrademembership"
          element={
            <React.Suspense fallback={<Loader />}>
              <UpgradeMembership />
            </React.Suspense>
          }
        />

        <Route
          path="profile/notificationssettings"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotificationsSettings />
            </React.Suspense>
          }
        />

        <Route
          path="profile/helpandsupport"
          element={
            <React.Suspense fallback={<Loader />}>
              <HelpAndSupport />
            </React.Suspense>
          }
        />

        <Route
          path="helpandsupport"
          element={
            <React.Suspense fallback={<Loader />}>
              <HelpAndSupport />
            </React.Suspense>
          }
        />

        <Route
          path="notifications"
          element={
            <React.Suspense fallback={<Loader />}>
              <Notifications />
            </React.Suspense>
          }
        />
      </Route>
      {/* </Suspense> */}
    </Routes>
  );
}

export default App;
